import { configureStore } from '@reduxjs/toolkit';
import changePasswordReducer from './user/changePasswordSlice';
import forgotPasswordReducer from './user/forgotPasswordSlice';
import loginReducer from './user/loginSlice';
import loginWithOTPReducer from './user/loginWithOTPSlice';
import profileReducer from './user/profileSlice';
import registerReducer from './user/registerSlice';
import resetPasswordReducer from './user/resetPasswordSlice';
import verifyEmailReducer from './user/verifyEmailSlice';
import withdrawReducer from './user/withdrawSlice';
import giftExchangeReducer from './user/giftExchangeSlice';
import showOrderReducer from './user/showOrderSlice';
import transactionHistoryReducer from './user/userTransactionSlice';
import getGiftsReducer from './user/getGiftSlice';
import getGiftsFlashSaleReducer from './user/getGiftFlashSaleSlice';
import productLazadaReducer from './shopcashbacks/getInfoProductLazadaSlice';
import productShopeeReducer from './shopcashbacks/getInfoProductShopeeSlice';
import productAccesstradeReducer from './shopcashbacks/getUrlProductAccesstradeSlice';
import voucherShopeeReducer from './vouchers/showVoucherShopeeSlice';
import voucherLazadaReducer from './vouchers/showVoucherLazadaSlice';
import voucherTikTokReducer from './vouchers/showVoucherTikTokSlice';

const store = configureStore({
  reducer: {
    changePassword: changePasswordReducer,
    forgotPassword: forgotPasswordReducer,
    login: loginReducer,
    loginWithOTP: loginWithOTPReducer,
    profile: profileReducer,
    register: registerReducer,
    resetPassword: resetPasswordReducer,
    verifyEmail: verifyEmailReducer,
    withdraw: withdrawReducer,
    giftExchange: giftExchangeReducer,
    showOrder: showOrderReducer,
    transactionHistory: transactionHistoryReducer,
    getGifts: getGiftsReducer,
    getGiftsFlashSale: getGiftsFlashSaleReducer,
    productLazada: productLazadaReducer,
    productShopee: productShopeeReducer,
    productAccesstrade: productAccesstradeReducer,
    voucherShopee: voucherShopeeReducer,
    voucherLazada: voucherLazadaReducer,
    voucherTikTok: voucherTikTokReducer,
  },
});

export default store;