import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { createWithdrawApi } from '../../api/user/withdrawApi';

// Thunk để tạo giao dịch rút tiền
export const createWithdrawAsync = createAsyncThunk(
  'withdraw/createWithdraw',
  async ({ withdrawData, token }, { rejectWithValue }) => {
    try {
      const response = await createWithdrawApi(withdrawData, token);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const withdrawSlice = createSlice({
  name: 'withdraw',
  initialState: {
    withdrawTransaction: null,
    loading: false,
    error: null,
  },
  reducers: {
    resetWithdraw: (state) => {
      state.withdrawTransaction = null;
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createWithdrawAsync.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createWithdrawAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.withdrawTransaction = action.payload;
      })
      .addCase(createWithdrawAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { resetWithdraw } = withdrawSlice.actions;
export default withdrawSlice.reducer;