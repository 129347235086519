import axios from 'axios';
import { BASE_URL } from '../configs';

// Thay đổi Password
export const changePassword = async (data, token) => {
  try {
    const response = await axios.post(`${BASE_URL}/change-password`, data, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response;
  } catch (error) {
    throw error;
  }
};

// Lấy trạng thái Password có tồn tại không
export const getPasswordExistStatus = async (token) => {
  try {
    const response = await axios.get(`${BASE_URL}/password-exist-status`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};