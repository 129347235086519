import React, { useEffect, useState } from 'react';
import { getTopUsers } from '../../api/user/topUserMonthApi';
import formatCurrency from '../utils/formatCurrency';

// Hàm để trả về đường dẫn ảnh cho từng merchant
const getMerchantLogo = (merchantName) => {
  switch (merchantName.toLowerCase()) {
    case 'shopee':
      return '/images/brands/logo-shopee.png';
    case 'lazada':
      return '/images/brands/logo-lazada.png';
    case 'tiktok':
      return '/images/brands/logo-tiktokshop.png';
    case 'tikivn':
      return '/images/brands/logo-tiki.png';
    case 'fpt_longchau':
      return '/images/brands/logo-nhathuoclongchau.png';
    default:
      return '/images/brands/logo-other.png';
  }
};

const rankMap = {
  0: 'Tân binh',
  1: 'Sơ cấp',
  2: 'Cao thủ',
  3: 'Đại cao thủ',
  4: 'Chiến thần',
  5: 'Huyền thoại'
};

const indexRank = {
  0: 'font-bold text-2xl sm:text-3xl text-purple-500',
  1: 'font-bold text-xl sm:text-2xl text-red-500',
  2: 'font-bold text-lg sm:text-xl text-amber-500',
  3: 'font-bold sm:text-lg text-yellow-400',
  4: 'font-bold sm:text-lg text-green-500',
  5: ''
};

const rankColors = {
  0: 'bg-gradient-to-r from-lime-300 to-green-600',
  1: 'bg-gradient-to-r from-teal-300 to-cyan-600',
  2: 'bg-gradient-to-r from-cyan-400 to-blue-600',
  3: 'bg-gradient-to-r from-fuchsia-400 to-pink-600',
  4: 'bg-gradient-to-r from-orange-400 via-amber-400 to-yellow-600',
  5: 'bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500'
};

const rankAnimation = {
  0: '',
  1: '',
  2: '',
  3: 'absolute top-0 left-0 w-full h-full bg-gradient-to-r from-transparent via-white to-transparent animate-shimmer',
  4: 'absolute top-0 left-0 w-full h-full bg-gradient-to-r from-transparent via-white to-transparent animate-shimmer',
  5: 'absolute top-0 left-0 w-full h-full bg-gradient-to-r from-transparent via-white to-transparent animate-shimmer'
};

const TopUsersWithMerchant = () => {
  const [topUsers, setTopUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [lastUpdated, setLastUpdated] = useState(null);
  const [monthType, setMonthType] = useState('current'); // Mặc định là tháng này

  useEffect(() => {
    const fetchTopUsers = async () => {
      setLoading(true);
      const { data, lastUpdated } = await getTopUsers({ monthType });
      if (!Array.isArray(data)) {
        console.error('API did not return an array:', data);
        setTopUsers([]);
      } else {
        setTopUsers(data);
      }
      setLastUpdated(lastUpdated);
      setLoading(false);
    };
  
    fetchTopUsers();
  }, [monthType]);  

  return (
    <div className="container mx-auto mt-3">
      <div className="bg-white shadow-md rounded-lg p-3">
        <div className="overflow-x-auto">
          {loading ? (
            <div className="text-center py-10 text-lg font-medium">Đang tải thông tin...</div>
          ) : (
            <table className="min-w-full">
              <tbody>
                {topUsers.map((user, index) => (
                  <tr key={user.idUser} className="border-b">
                    <td className={`px-2 text-center ${indexRank[index]}`}>{index + 1}</td>
                    <td className="py-2 flex items-center justify-between w-full">
                      <div className="flex items-center w-2/3 sm:w-1/2">
                        <div className='border border-green-700 rounded-full'>
                          <img
                            src={user.avatar || '/images/users/avatar-default.png'}
                            alt="Ảnh đại diện"
                            className="w-9 h-9 rounded-full object-cover"
                          />
                        </div>
                        <div className="ml-2 font-medium">
                          <div>{user.fullName || `User-${user.idUser}`}</div>
                          <div
                            className={`text-sm text-white ${rankColors[user.rankUser]} inline-block rounded px-2 relative overflow-hidden`}
                          >
                            {rankMap[user.rankUser]}
                            <div className={rankAnimation[user.rankUser]}></div>
                          </div>
                          <div className="flex items-center space-x-1 -mt-1">
                            <span className="text-gray-700 font-normal text-xs">Hoàn tiền từ</span>
                            {user.merchantNames.map((merchant, i) => (
                              <img
                                key={i}
                                src={getMerchantLogo(merchant)}
                                alt={merchant}
                                className="w-4 h-4"
                              />
                            ))}
                          </div>
                        </div>
                      </div>
                      <div className="py-2 text-right w-1/3 sm:w-1/2">
                        <span className="text-white px-2 py-1 rounded-md bg-gradient-to-r from-emerald-500 to-green-700">
                          +{formatCurrency(user.totalRefundCredit)}
                        </span>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
        {/* Nút Tháng này và Tháng trước căn giữa */}
        <div className="flex justify-center mt-4">
          <button
            className={`px-4 py-1 mr-1 rounded-md transition duration-300 ${
              monthType === 'previous' ? 'bg-green-700 text-white' : 'border text-black'
            }`}
            onClick={() => setMonthType('previous')}
          >
            Tháng trước
          </button>
          <button
            className={`px-4 py-1 rounded-md transition duration-300 ${
              monthType === 'current' ? 'bg-green-700 text-white' : 'border text-black'
            }`}
            onClick={() => setMonthType('current')}
          >
            Tháng này
          </button>
        </div>
        <div className="text-center text-sm text-gray-600 mt-4">
          {lastUpdated
            ? `Cập nhật gần nhất: ${new Date(lastUpdated * 1000).toLocaleString('vi-VN')}`
            : 'Chưa có thông tin cập nhật'}
        </div>
      </div>
    </div>
  );
};

export default TopUsersWithMerchant;