import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';
import { changePassword, getPasswordExistStatus } from '../../api/user/changePassword';

// Lấy trạng thái mật khẩu
export const fetchPasswordStatus = createAsyncThunk(
  'changePassword/fetchPasswordStatus',
  async (_, thunkAPI) => {
    try {
      const token = Cookies.get('token');
      const response = await getPasswordExistStatus(token);
      return response.data.hasPassword;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response?.data?.error || 'Không thể lấy trạng thái mật khẩu.');
    }
  }
);

// Đổi mật khẩu
export const changePasswordThunk = createAsyncThunk(
  'changePassword/changePassword',
  async ({ currentPassword, newPassword }, thunkAPI) => {
    try {
      const token = Cookies.get('token');
      const response = await changePassword({ currentPassword, newPassword }, token);
      return response.data; // Chỉ trả về message từ backend
    } catch (error) {
      const errorMessage = error.response?.data?.error || 'Có lỗi xảy ra. Vui lòng thử lại.';
      return thunkAPI.rejectWithValue(errorMessage);
    }
  }
);

const changePasswordSlice = createSlice({
  name: 'changePassword',
  initialState: {
    isLoading: false,
    error: null,
    success: false,
    hasPassword: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Lấy trạng thái mật khẩu
      .addCase(fetchPasswordStatus.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchPasswordStatus.fulfilled, (state, action) => {
        state.isLoading = false;
        state.hasPassword = action.payload;
      })
      .addCase(fetchPasswordStatus.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      // Đổi mật khẩu
      .addCase(changePasswordThunk.pending, (state) => {
        state.isLoading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(changePasswordThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.success = true;
      })
      .addCase(changePasswordThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export default changePasswordSlice.reducer;