import axios from 'axios';
import { BASE_URL } from '../configs';

// API để tạo giao dịch rút tiền
export const createWithdrawApi = async (withdrawData, token) => {
    const response = await axios.post(`${BASE_URL}/api/v1/user/withdraw`, withdrawData, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    return response.data;
};