import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { createGiftExchangeApi } from '../../api/user/giftExchangeApi';

export const createGiftExchangeAsync = createAsyncThunk(
  'giftExchange/createGiftExchange',
  async ({ giftExchangeData, token }, { rejectWithValue }) => {
    try {
      const response = await createGiftExchangeApi(giftExchangeData, token);
      return response; // Trả về dữ liệu từ API, bao gồm giftCode
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const giftExchangeSlice = createSlice({
  name: 'giftExchange',
  initialState: {
    giftTransaction: null,
    giftCode: null, // Lưu giftCode
    loading: false,
    error: null,
  },
  reducers: {
    resetGiftExchange: (state) => {
      state.giftTransaction = null;
      state.giftCode = null; // Reset giftCode
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createGiftExchangeAsync.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createGiftExchangeAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.giftTransaction = action.payload.transaction;
        state.giftCode = action.payload.giftCode; // Cập nhật giftCode
      })
      .addCase(createGiftExchangeAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { resetGiftExchange } = giftExchangeSlice.actions;
export default giftExchangeSlice.reducer;