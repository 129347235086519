import axios from 'axios';
import { BASE_URL } from '../configs';

// API lấy thông tin các giao dịch rút tiền, đổi quà
export const getTransactionHistoryApi = async (userId, token) => {
    const response = await axios.get(`${BASE_URL}/api/v1/user/transactions/${userId}`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    return response.data;
};