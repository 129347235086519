import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchVouchersAsync, incrementPage, resetPage, setCategory } from '../../store/vouchers/showVoucherLazadaSlice';
import formatCurrency from '../utils/formatCurrency';
import timestampToDDMM from '../utils/timestampToDDMM';

const VoucherLazadaList = () => {
  const dispatch = useDispatch();
  const vouchers = useSelector((state) => state.voucherLazada.vouchers);
  const page = useSelector((state) => state.voucherLazada.page);
  const totalPages = useSelector((state) => state.voucherLazada.totalPages);
  const status = useSelector((state) => state.voucherLazada.status);
  const category = useSelector((state) => state.voucherLazada.category);
  const user = useSelector((state) => state.profile.user);
  const idUser = user?.idUser || 'notlogin';
  const [copiedVoucherId, setCopiedVoucherId] = useState(null);
  const [expandedNoteId, setExpandedNoteId] = useState(null);

  useEffect(() => {
    dispatch(resetPage());
    dispatch(fetchVouchersAsync({ page: 1, category }));
  }, [dispatch, category]);

  useEffect(() => {
      const handleClickOutside = (event) => {
        if (!event.target.closest('.voucher-note')) {
          setExpandedNoteId(null);
        }
      };
  
      if (expandedNoteId) {
        document.addEventListener('click', handleClickOutside);
      } else {
        document.removeEventListener('click', handleClickOutside);
      }
  
      return () => {
        document.removeEventListener('click', handleClickOutside);
      };
    }, [expandedNoteId]);

  const createLinkLazAff = (linkPromotion) => {
    const targetUrl = `https://c.lazada.vn/t/c.0EgqFM?url=${encodeURIComponent(linkPromotion)}&sub_aff_id=tui3gang&sub_id1=${idUser}`;
    
    if (idUser === "notlogin") {
      window.open(`/redirect?urlnotlogin=${encodeURIComponent(targetUrl)}`); 
    } else {
      window.open(targetUrl); 
    }
  };

  const handleCopy = (id, code, linkPromotion) => {
    navigator.clipboard.writeText(code);
    setCopiedVoucherId(id);
    setTimeout(() => {
      createLinkLazAff(linkPromotion);
    }, 500);
  };

  const handleBuyNow = (linkPromotion) => {
    createLinkLazAff(linkPromotion);
  };

  const toggleNote = (id) => {
    setExpandedNoteId((prevId) => (prevId === id ? null : id)); // Đóng nếu đang mở và mở nếu đang đóng
  };

  const handleLoadMore = () => {
    if (page < totalPages && status === 'succeeded') {
      dispatch(incrementPage());
      dispatch(fetchVouchersAsync({ page: page + 1, category }));
    }
  };

  const handleFilterChange = (category) => {
    dispatch(setCategory(category));
  };

  const filteredVouchers = category === 'Tất cả'
    ? vouchers
    : vouchers.filter(voucher => voucher.promotionCat === category);

  return (
    <div>
      <div className="overflow-x-auto whitespace-nowrap mb-4 py-2">
        <button
          className={`px-4 py-2 m-1 rounded ${category === 'Tất cả' ? 'bg-green-100 text-green-700' : 'bg-gray-100 text-gray-700'}`}
          onClick={() => handleFilterChange('Tất cả')}
        >
          Tất cả
        </button>
        {['Toàn sàn', 'Freeship Max', 'Thanh toán'].map(categoryItem => (
          <button
            key={categoryItem}
            className={`px-4 py-2 m-1 rounded ${category === categoryItem ? 'bg-green-100 text-green-700' : 'bg-gray-100 text-gray-700'}`}
            onClick={() => handleFilterChange(categoryItem)}
          >
            {categoryItem}
          </button>
        ))}
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
        {filteredVouchers.map((voucher) => (
          <div key={voucher._id} className="p-1 border rounded-md shadow-md flex flex-col bg-white">
            <div className="flex">
              <div className="w-1/4 flex flex-col items-center justify-center">
                <img src={voucher.cover} alt="Voucher cover" className="w-11 h-auto rounded-xl mb-1 bg-[#0a00c5]" />
                <p className="text-sm">{voucher.promotionCat}</p>
              </div>
              <div className="w-full px-2 flex flex-col justify-center">
                <p className="text-md font-bold">
                  {voucher.promotionType === 'percent' ? `Giảm ${voucher.discountAmount}% tối đa ${formatCurrency(voucher.maxDiscount)}` : `Giảm ${formatCurrency(voucher.discountAmount)}`}
                </p>
                <div className="flex justify-between">
                  <div className="items-start">
                    <p className="text-sm text-gray-500">Đơn tối thiểu: {formatCurrency(voucher.minOrderAmount)}</p>
                    <p className="text-sm text-gray-500">
                      {voucher.startTime && voucher.startTime > Date.now() ? `Hiệu lực: ${timestampToDDMM(voucher.startTime / 1000)}` : `HSD: ${timestampToDDMM(voucher.endTime / 1000)}`}
                    </p>
                    {voucher.promotionNote &&
                      <p
                      key={`${voucher._id}-note`}
                      className={`text-sm voucher-note cursor-pointer ${expandedNoteId === voucher._id ? 'text-gray-500' : 'text-gray-500'}`}
                        onClick={() => toggleNote(voucher._id)}
                      >
                        {expandedNoteId === voucher._id ? `Lưu ý: ${voucher.promotionNote}` : 'Lưu ý:... Xem chi tiết'}
                      </p>
                    }
                    {voucher.promotionCode ? (
                      <p className="text-xs text-gray-400">#Mã code nhập tay</p>
                    ) : (
                      <p className="text-xs text-gray-400">#Lưu mã trên trang mở ra</p>
                    )}
                  </div>
                  <div className="text-sm flex items-center justify-end">
                    {voucher.entryMethod === "manual" ? (
                      <button
                        className="text-green-700 font-medium px-4 py-1 rounded bg-green-100 getvoucher"
                        onClick={() => handleCopy(voucher._id, voucher.promotionCode, voucher.linkPromotion)}
                      >
                        {copiedVoucherId === voucher._id ? 'Đã copy!' : 'Copy mã'}
                      </button>
                    ) : (
                      <button
                        className="text-green-700 font-medium px-4 py-1 rounded bg-green-100 getvoucher"
                        onClick={() => handleBuyNow(voucher.linkPromotion)}
                      >
                        Lưu mã
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      {status === 'loading' && <p>Loading...</p>}
      {page < totalPages && (
        <div className="flex justify-center my-4">
          <button
            className="bg-green-100 font-medium text-green-700 px-4 py-2 rounded"
            onClick={handleLoadMore}
          >
            Xem thêm voucher
          </button>
        </div>
      )}
    </div>
  );
};

export default VoucherLazadaList;