import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchGiftsApi } from '../../api/user/getGiftApi';

// Thunk để lấy danh sách quà tặng
export const fetchGifts = createAsyncThunk('gifts/fetchGifts', async (_, { rejectWithValue }) => {
    try {
        const response = await fetchGiftsApi();
        return response;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

const giftSlice = createSlice({
    name: 'getGifts',
    initialState: {
        giftList: [],
        status: 'idle',
        error: null,
    },
    reducers: {
        // Nếu có các reducers khác cần giữ lại, bạn có thể thêm ở đây
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchGifts.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchGifts.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.giftList = action.payload;
            })
            .addCase(fetchGifts.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            });
    },
});

export default giftSlice.reducer;