import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchFlashSaleVouchersApi } from '../../api/user/getGiftFlashSaleApi';

// Thunk để lấy thông tin các quà tặng trong flash sale
export const fetchFlashSaleVouchers = createAsyncThunk(
    'getGiftsFlashSale/fetchFlashSaleVouchers',
    async (_, { rejectWithValue }) => {
        try {
            const response = await fetchFlashSaleVouchersApi();
            return response;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

// Tạo slice cho flash sale
const getGiftsFlashSaleSlice = createSlice({
    name: 'getGiftsFlashSale',
    initialState: {
        flashSaleList: [],
        status: 'idle',
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchFlashSaleVouchers.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchFlashSaleVouchers.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.flashSaleList = action.payload;
            })
            .addCase(fetchFlashSaleVouchers.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            });
    },
});

export default getGiftsFlashSaleSlice.reducer;