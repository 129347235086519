import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchOrders } from '../../api/user/showMyOrder';

// Thunk để lấy đơn hàng của người dùng
export const fetchOrdersAsync = createAsyncThunk(
  'showOrders/fetchOrders',
  async ({ idUser, limit, skip }, { rejectWithValue }) => {
    try {
      const response = await fetchOrders(idUser, limit, skip);
      return response.data; // API trả về { totals, conversions }
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const showOrderSlice = createSlice({
  name: 'showOrders',
  initialState: {
    orders: [],
    totals: {
      approved: 0,
      pending: 0,
      cancelled: 0,
      bonus: 0,
    },
    status: 'idle',
    error: null,
  },
  reducers: {
    resetOrders: (state) => {
      state.orders = [];
      state.totals = {
        approved: 0,
        pending: 0,
        cancelled: 0,
        bonus: 0,
      };
      state.status = 'idle';
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchOrdersAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchOrdersAsync.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.orders = [...state.orders, ...action.payload.conversions];
        state.totals = action.payload.totals;
      })
      .addCase(fetchOrdersAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });
  },
});

export const { resetOrders } = showOrderSlice.actions;
export default showOrderSlice.reducer;