import axios from 'axios';
import { BASE_URL } from '../configs';

// API để tạo giao dịch đổi quà
export const createGiftExchangeApi = async (giftExchangeData, token) => {
    const response = await axios.post(`${BASE_URL}/api/v1/user/giftexchange`, giftExchangeData, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    return response.data;
};