import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getTransactionHistoryApi } from '../../api/user/transactions';

// Thunk để lấy lịch sử giao dịch
export const fetchTransactionHistoryAsync = createAsyncThunk(
  'transactionHistory/fetchTransactionHistory',
  async ({ userId, token }, { rejectWithValue }) => {
    try {
      const response = await getTransactionHistoryApi(userId, token);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const transactionHistorySlice = createSlice({
  name: 'transactionHistory',
  initialState: {
    transactions: [],
    loading: false,
    error: null,
  },
  reducers: {
    resetTransactionHistory: (state) => {
      state.transactions = [];
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTransactionHistoryAsync.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchTransactionHistoryAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.transactions = action.payload;
      })
      .addCase(fetchTransactionHistoryAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { resetTransactionHistory } = transactionHistorySlice.actions;
export default transactionHistorySlice.reducer;