import axios from 'axios';
import Cookies from 'js-cookie';
import { BASE_URL } from '../configs';

// API để lấy danh sách các quà tặng trong flash sale
export const fetchFlashSaleVouchersApi = async () => {
    const token = Cookies.get('token');
    const response = await axios.get(`${BASE_URL}/api/v1/user/gift-flash-sale`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    return response.data;
};